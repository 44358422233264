export default () => {

	$(".navbar-nav .nav-link").on('click', function() {
		var id = $(this).attr('href');

		$([document.documentElement, document.body]).animate({
			scrollTop: $(id).offset().top
		}, 1000);
	});

};
