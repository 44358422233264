// bootstrap - remove or uncomment any unused script
// import 'bootstrap/js/dist/alert'
// import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util';

// controllers
import common from './controllers/common';

const controllers = {};

$(() => {
  common();

  $('[data-controller]').each((index, element) => {
    const controller = $(element).data('controller');

    if (typeof controllers[controller] === 'function') {
      controllers[controller]();
    }
  });
});
